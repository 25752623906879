/*
 * @Author: zhangzc
 * @Email: zhicheng.zhang@medbanks.cn
 * @Date: 2019-11-22 11:13:39
 * @Last Modified by: mengying.yao
 * @Last Modified time: 2020-07-28 16:41:56
 * @Description: 直播结束结果页
 * @Route: /result
 */

<template>
  <div class="im-detail">
    <h2 class="title">
      客服配置页面 / 查看
    </h2>

    <ul>
      <li>
        <span class="label">渠道名称</span>
        <span class="value">{{ imData.channelName }}</span>
      </li>
      <li>
        <span class="label">页面名称</span>
        <span class="value">{{ imData.pageTitle }}</span>
      </li>
      <li>
        <span class="label">页面信息备注</span>
        <span class="value">{{ imData.pageRemark }}</span>
      </li>
      <li>
        <span class="label">容联客服页面accessid</span>
        <span class="value">{{ imData.accessId }}</span>
      </li>
    </ul>
    <p>按钮配置（配置页面上显示的按钮）</p>
    <table>
      <thead>
        <tr>
          <td>按钮名称</td>
          <td style="width: 330px;">
            显隐设置
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>去参保</td>
          <td>
            {{ imData.isShowInsured ? '显示' : '隐藏' }}
          </td>
        </tr>
        <tr>
          <td>电话客服</td>
          <td>
            {{ imData.isShowMobile ? '显示' : '隐藏' }}
            <div v-if="imData.isShowMobile">
              <span class="label">电话号码</span>
              <span class="value">{{ imData.serviceMobile }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>视频客服</td>
          <td>
            {{ imData.isShowVideo ? '显示' : '隐藏' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import { imChannel } from '@/api';

export default {
  data() {
    return {
      imData: {},
    };
  },
  created() {
    imChannel.getDetailByChannelId({
      Id: this.$route.params.id,
    })
      .then((res) => {
        this.imData = res.data;
      });
  },
};

</script>

<style lang="scss" scoped>
.im-detail {
  font-size: 16px;

  .title {
    margin-bottom: 40px;
  }
}
ul {
  list-style: none;
  li {
    margin-bottom: 20px;
  }
}
.label {
  display: inline-block;
  width: 200px;
  color: #666;
  flex-shrink: 0;
  // margin-bottom: 20px;
}
.value {
  color: #333;
  font-weight: bold;
}
p {
  margin-bottom: 20px;
  margin-top: 40px;
}

table {
  border-collapse: collapse;
  text-align: center;
  margin-left: 80px;
  thead {
    background: #f0f0f0;
  }

  tr {
    border-top: 1px solid #ddd;
    &:nth-last-child(1) {
      border-bottom: 1px solid #ddd;
    }

    td {
      padding: 10px 10px;
      border-left: 1px solid #ddd;
      &:nth-last-child(1) {
        border-right: 1px solid #ddd;
      }

      > div {
        display: flex;

        .label {
          text-align: left;
          width: 80px;
        }
        .value {
          text-align: left;
        }
      }
    }
  }
}

</style>
