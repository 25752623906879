<template>
  <div class="im_index">
    <h2 class="title">客服配置页面 / 创建</h2>
    <el-form ref="form" :rules="rules" :model="form" label-width="auto">
      <el-form-item label="渠道名称" required prop="channelId">
        <span v-if="this.$route.query.id">{{ form.channelName }}</span>
        <el-select
          v-else
          v-model="channel"
          :disabled="!!$route.query.id"
          :remote-method="searchChannel"
          value-key="channelEncode"
          filterable
          remote
          placeholder="请选择"
        >
          <el-option
            v-for="item in channelList"
            :key="item.channelEncode"
            :label="item.channelName"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="页面名称" required prop="pageTitle">
        <el-input v-model="form.pageTitle" class="usually_input" />
      </el-form-item>

      <el-form-item label="页面信息备注">
        <el-input
          v-model="form.pageRemark"
          :rows="3"
          type="textarea"
          maxlength="30"
          class="usually_input"
          placeholder="不要超过30个汉字"
        />
      </el-form-item>
      <el-form-item label="容联客服页面accessid" prop="accessId">
        <el-input v-model="form.accessId" style="width: 400px;" />
      </el-form-item>
      <p class="table_title">按钮配置（配置页面上显示的按钮）</p>
      <table>
        <thead>
          <tr>
            <td>按钮名称</td>
            <td style="width: 330px;">显隐设置</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>去参保</td>
            <td>
              <el-radio-group v-model="form.isShowInsured" class="table_radio">
                <el-radio :label="1"> 显示 </el-radio>
                <el-radio :label="0"> 隐藏 </el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td>电话客服</td>
            <td>
              <el-radio-group v-model="form.isShowMobile" class="table_radio">
                <el-radio :label="1"> 显示 </el-radio>
                <el-radio :label="0"> 隐藏 </el-radio>
              </el-radio-group>
              <el-form-item
                v-if="form.isShowMobile"
                label-width="80px"
                label="电话号码"
                prop="serviceMobile"
              >
                <el-input v-model="form.serviceMobile" class="usually_input" />
              </el-form-item>
            </td>
          </tr>
          <tr>
            <td>视频客服</td>
            <td>
              <el-radio-group v-model="form.isShowVideo">
                <el-radio :label="1"> 显示 </el-radio>
                <el-radio :label="0"> 隐藏 </el-radio>
              </el-radio-group>
            </td>
          </tr>
        </tbody>
      </table>
    </el-form>
    <div class="footer">
      <el-button @click="submit" type="primary"> 提交 </el-button>
    </div>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import { mapState } from 'vuex';
import { imChannel } from '@/api';

export default {
  data() {
    const validChannel = (rule, val, callback) => {
      if (!val) {
        callback(new Error('请选择渠道'));
      } else {
        this.verifyField('channelName', isValid => {
          if (isValid) {
            callback(new Error('渠道名称不能重复'));
          } else {
            callback();
          }
        });
      }
    };

    const validPageTitle = (rule, val, callback) => {
      if (!val) {
        callback(new Error('请输入页面名称'));
      } else {
        this.verifyField('pageTitle', isValid => {
          if (isValid) {
            callback(new Error('页面名称不能重复'));
          } else {
            callback();
          }
        });
      }
    };
    return {
      form: {
        Id: this.$route.query.id,
        channelId: '', // 渠道ID
        channelName: '', // 渠道名称
        accessId: '', // 容联客服ID
        pageTitle: '', // 页面名称
        pageRemark: '', // 页面备注
        isShowInsured: 0,
        isShowMobile: 0,
        isShowVideo: 0,
        // insuredLink: '', // 参保链接
        serviceMobile: '400001366', // 客服电话
      },
      rules: {
        channelId: {
          validator: validChannel,
          trigger: 'change',
        },
        accessId: {
          required: true,
          message: '请输入容联客服页面accessId',
          trigger: 'blur',
        },
        pageTitle: [
          {
            required: true,
            message: '请输入页面名称',
            trigger: 'blur',
          },
          {
            validator: validPageTitle,
            trigger: 'blur',
          },
        ],
        // insuredLink: {
        //     required: true, message: '请输入参保链接', trigger: 'blur',
        // },
        serviceMobile: {
          required: true,
          message: '请输入客服电话',
          trigger: 'blur',
        },
      },
      channel: {},
    };
  },
  computed: mapState('imChannel', {
    channelList: state => state.channelList,
  }),
  watch: {
    channel(val) {
      this.form.channelId = val.channelEncode;
      this.form.channelName = val.channelName;
    },
  },

  created() {
    // this.channel = {};
    this.getChannelList();
    if (this.$route.query.id) {
      imChannel
        .getDetailByChannelId({
          Id: this.$route.query.id,
        })
        .then(res => {
          this.form = res.data;
          this.channel = {
            channelEncode: res.data.channelId,
            channelName: res.data.channelName,
          };
        });
    }
  },
  methods: {
    verifyField(type, callback) {
      imChannel
        .verifyField({
          Id: this.$route.query.id,
          type,
          value: this.form[type],
        })
        .then(res => {
          callback(res.data);
        });
    },
    searchChannel(val) {
      this.getChannelList({
        channelName: val,
      });
    },
    getChannelList(params) {
      this.$store.dispatch('imChannel/getChannelList', params);
    },

    // channelChange(val) {
    //     console.log(val);
    // },
    submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const loading = Loading.service({
            fullscreen: true,
          });
          await this.$store.dispatch('imChannel/save', this.form);
          loading.close();
          this.$router.go(-1);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table_title {
  color: #606266;
  margin-bottom: 20px;
}
table {
  border-collapse: collapse;
  text-align: center;
  margin-left: 80px;
  thead {
    background: #f0f0f0;
  }

  tr {
    border-top: 1px solid #ddd;
    &:nth-last-child(1) {
      border-bottom: 1px solid #ddd;
    }

    td {
      padding: 10px 10px;
      border-left: 1px solid #ddd;
      &:nth-last-child(1) {
        border-right: 1px solid #ddd;
      }

      /deep/ .el-form-item {
        margin-bottom: 0;
      }

      .table_radio {
        margin-bottom: 10px;
      }
    }
  }
}

.footer {
  margin-top: 20px;
  text-align: center;
}
</style>
