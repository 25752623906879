<template>
  <div class="im_index">
    <h2 class="title">客服配置页面</h2>
    <div class="headers">
      <div class="search">
        <el-input
          v-model="channelName"
          class="search_input"
          size="small"
          placeholder="请输入渠道名称"
        />
        <el-button @click="search" type="primary" size="small"> 搜索 </el-button>
      </div>
      <el-button @click="$router.push('/im-edit')" type="primary" size="small"> 创建 </el-button>
    </div>
    <el-table :data="list" border style="width: 100%;">
      <el-table-column prop="channelName" label="渠道名称" />
      <el-table-column prop="pageTitle" label="页面名称" />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="toShow(scope.row.id)" type="text" size="small"> 查看 </el-button>
          <el-button @click="toEdit(scope.row.id)" type="text" size="small"> 编辑 </el-button>
          <el-button @click="showLinkDialog(scope.row)" type="text" size="small">
            查看链接
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer">
      <el-pagination
        :current-page.sync="pageNum"
        :total="total"
        :page-size="pageSize"
        background
        layout="prev, pager, next"
      />
    </div>

    <el-dialog :title="activeData.pageTitle" :visible.sync="showLink" width="50%">
      <span class="service_link">页面链接：{{ serviceLink }}</span>
      <el-table :data="fields" size="mini" border>
        <el-table-column prop="name" label="参数名称" />
        <el-table-column prop="required" label="是否必填" />
        <el-table-column prop="desc" label="参数说明" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showLink = false">取 消</el-button>
        <el-button @click="copy" type="primary">复 制</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { copy } from '@/utils/util';
import Config from '@/config';
import fields from './link-fields';

export default {
  data() {
    return {
      fields,
      channelName: '',
      pageNum: 1,
      pageSize: 10,
      activeData: {},
      showLink: false,
      serviceLink: '',
    };
  },

  computed: mapState('imChannel', {
    list: state => state.list,
    total: state => +state.total,
  }),
  watch: {
    pageNum: {
      handler: 'getList',
      immediate: true,
    },
  },

  methods: {
    getList() {
      const { channelName, pageNum, pageSize } = this;
      this.$store.dispatch('imChannel/getList', {
        channelName,
        pageSize,
        pageNum,
      });
    },
    search() {
      if (this.pageNum === 1) {
        this.getList();
      } else {
        this.pageNum = 1;
      }
    },
    toShow(id) {
      this.$router.push({
        path: `/im-detail/${id}`,
      });
    },
    toEdit(id) {
      this.$router.push({
        path: '/im-edit',
        query: {
          id,
        },
      });
    },
    showLinkDialog(data) {
      this.serviceLink = `${Config.h5Url}?serviceChannel=${data.channelId}`;
      this.activeData = data;
      this.showLink = true;
    },
    copy() {
      copy(this.serviceLink);
      this.$message.success('复制成功');
      this.showLink = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .search {
    display: flex;
    align-items: center;
    .search_input {
      margin-right: 10px;
    }
  }
}

.service_link {
  display: block;
  margin-bottom: 20px;
  line-height: 24px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
