export default [
  {
    name: 'serviceChannel',
    required: '是（由客服系统负责人提供）',
    desc: '客服渠道ID',
  },
  {
    name: 'sourceUrl',
    required: '如果显示去参保按钮则必填',
    desc: '客服页面点去“去参保”按钮跳转链接',
  },
  {
    name: 'saleChannelId',
    required: '否',
    desc: '渠道ID（埋点数据）',
  },
  {
    name: 'openid',
    required: '否',
    desc: '用户ID（埋点数据）',
  },
  {
    name: 'skuCode',
    required: '否',
    desc: '商品code（埋点数据）',
  },
  {
    name: 'nickName',
    required: '否',
    desc: '容联后台显示用户昵称',
  },
];
